/* Halftone Styles */
.halftone {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  filter: contrast(50);
  mix-blend-mode: screen;
  --stop1: 0.06rem;
  --stop2: 0.65rem;
}

.halftone::before {
  content: '';
  position: absolute;
  inset: 0;
  background-image:
    radial-gradient(circle at center, black var(--stop1), transparent var(--stop2)),
    radial-gradient(circle at center, black var(--stop1), transparent var(--stop2));
  background-size: 1.3rem 1.3rem;
  background-position: 0 0, 0.65rem 0.65rem;
  -webkit-mask-image: linear-gradient(rgb(0 0 0), rgb(0 0 0 / 0.5));
  mask-image: linear-gradient(rgb(0 0 0), rgb(0 0 0 / 0.5));
}

/* Input Styles */
#interviewInput:focus-visible {
  outline: none !important;
  caret-color: #e7e5e4;
}

#interviewInput::placeholder {
  color: #a8a29e;
}

button:focus-visible {
  outline: none !important;
  color: #e7e5e4;
  border-color: #e7e5e4;
}

.headerLogo svg {
  width: 100%;
  height: 100%;
  fill: #e7e5e4;
}

/* Avatar SVG Styles */
.proxy-avatar .cls-1,
.proxy-avatar .cls-2,
.user-avatar .cls-1,
.user-avatar .cls-3,
.system-avatar .cls-1,
.system-avatar .cls-2 {
  fill: none;
  stroke: #292524;
  stroke-width: 2px;
}

.proxy-avatar .cls-1,
.user-avatar .cls-1,
.system-avatar .cls-1 {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.proxy-avatar .cls-2,
.user-avatar .cls-3,
.system-avatar .cls-2 {
  stroke-miterlimit: 10;
}

.proxy-avatar .cls-3,
.user-avatar .cls-2 {
  fill: #292524;
}

/* Animation Styles */
@keyframes blinking {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes ellipses {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes bobbing {

  0%,
  100% {
    transform: translateY(1px);
  }

  50% {
    transform: translateY(-1px);
  }
}

.bobbing {
  animation: bobbing 3s ease-in-out infinite;
}

/* Message Loading Styles */
.thinking {
  animation: blinking .8s linear infinite;
  cursor: wait;
}

.message,
.avatar
 {
  opacity: 0;
}

.message.animate-fadeIn,
.avatar.animate-fadeIn {
  animation: fadeIn .8s forwards;
}

/* Typing Indicator Styles */
.typing-indicator span {
  animation: ellipses 1.6s infinite;
  padding-right: .2rem;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.3s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: .6s;
}